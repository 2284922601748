import React from "react"
import { makeStyles, withStyles } from "@material-ui/core/styles"
import theme from "../gatsby-theme-material-ui-top-layout/theme"
import { Link, useTranslation } from "gatsby-plugin-react-i18next"
import {
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@material-ui/core"
import { Section } from "../components/section"

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14,
  }
}))(TableCell)

const LeftColumnCell = withStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.action.hover,
        fontWeight: "bold",
    }
  }))(TableCell)

const useStyles = makeStyles({
  table: {
    minWidth: 500,
    tableLayout: "fixed",
    width:"100%"
  },
  container: {
    marginBottom: theme.spacing(2)
  }
})

export default function InformationTable(props) {
  const classes = useStyles()
  const {
      rows,
      columns,
      firstColumnWidth,
  } = props
  const {t} = useTranslation();

  return (
    <TableContainer component={Paper} className={classes.container}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
              <StyledTableCell style={{width:firstColumnWidth}}>{columns[0]}</StyledTableCell>
              {columns.slice(1).map((col)=>(
                  <StyledTableCell style={{width:"auto"}}key={col}>{t(col)}</StyledTableCell>
              ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row[0]}>
                <LeftColumnCell>{row[0]}</LeftColumnCell>
                {row.slice(1).map((r, i) => (
                    <StyledTableCell key={i}>{r}</StyledTableCell>
                ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}