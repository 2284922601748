import React from "react"
import { Box } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { SectionTitle } from "./sectionTitle"

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  }
}));

export function Section(props) {
  const { title } = props
  const classes = useStyles()


  return (
    <Box className={classes.root}>
      <SectionTitle>{title}</SectionTitle>
      {props.children}
    </Box>
  )
}