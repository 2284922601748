import {
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { graphql } from 'gatsby';
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";
import React, { useState } from "react";
import AccentFrame from "../components/accentFrame";
import Definition from "../components/definition";
import InformationTable from "../components/informationTable";
import Layout from "../components/layout";
import Li from "../components/li";
import { SectionTitle } from "../components/sectionTitle";

const useStyles = makeStyles(() => ({
  imgTbiTgpni: {
    width: "100%",
    height: "85vh",
    objectFit: "contain"
  },
  imgTbiCn: {
    width: "100%",
    height: "35vh",
    objectFit: "contain",
  },
  imgTbi: {
    width: "100%",
    height: "35vh",
    objectFit: "contain",
  },
  low: {
    color: "#8f4a9e"
  },
  high: {
    color: "#2e2e7a"
  },
  present: {
    color:"#00b2c9"
  },
  notPresent: {
    color:"#0069ac"
  }
}))

export default function Screening() {
  const {t} = useTranslation();
  const columns = [t("Tests"), t("Integrated biochemical test"), t("Nuchal translucency ultrasound"), t("Non-invasive prenatal testing (NIPT)")];
  const [selected, setSelected] = useState("tbi");
  const [inImage, setInImage] = useState(false);
  const [inPercentage, setInPercentage] = useState(false);

  const handleChange = (event, newValue) => {
    setSelected(newValue);
  };

  const handleToggleInImage = () => {
    setInImage(!inImage)
  }

  const handlePercentageToggle = () => {
    setInPercentage(!inPercentage);
  }

  const rows = [
    [
      t("Definition"), 
      t("Consists of blood test"),
      t("Consists of measuring the thickness of the fetal neck (space between the skin and the spine) by ultrasound."),
      t("Consists of one blood test during pregnancy to analyze free fetal DNA fragments from the placenta circulating in the maternal blood.")
    ],
    [
      t("Notes"),
      <ul>
        <li>
          {t("Note1")}
        </li>
        <li>
          {t("Note2")}
        </li>
      </ul>
      ,
      <ul>
        <li>
          {t("Note1")}
        </li>
        <li>
          {t("Note3")}
        </li>
      </ul>
      ,
      <ul>
        <li>
          {t("Note4")}
        </li>
        <li>
          {t("Note5")}
        </li>
        <li>
          {t("Note6")}
        </li>
      </ul>
    ],
  ];

  return (
  <Layout pageTitle={t("Screening")} next="/screening/part1">
      <SectionTitle>
        {t("What is prenatal screening?")}
      </SectionTitle>   
      <Typography component="span" color="secondary" gutterBottom={true}>
        <em>
          <Trans i18nKey="prenatalScreeningDef">
            Prenatal screening is a <Definition word="nonInvasive">non-invasive</Definition> and safe procedure. 
          </Trans>
        </em>
        <br/>
        <br/>
      </Typography>
      <Typography component="span" gutterBottom={true}>
        <Trans i18nKey="screeningStat1">
          The results of your screening test will indicate a low or high <Definition word="probability">probability</Definition> 
          that your fetus has trisomy. They only show probability and do not provide any guarantees. If your probability is 
          low, this is no guarantee that your fetus does not have trisomy. By the same logic, if your probability is high, your fetus 
          does not necessarily have trisomy. 
        </Trans>
      </Typography>
      <SectionTitle>
        {t("Québec Prenatal Screening Program")}
      </SectionTitle>
      <Typography>
        {t("The Québec Prenatal Screening Program offers the possibility of prenatal screening to all pregnant women in Quebec who want it. The program includes three tests:")}
      </Typography>
        <ul>
          <Li><strong>{t("The biochemical test (with or without nuchal translucency)")}</strong></Li>
          <Li><strong>{t("The Genomic test")}</strong></Li>
        </ul>
      <Typography component="span" paragraph={true}>
        <Trans i18nKey="screeningProgram">
          In this program, women are first offered an <Definition word="tbi">integrated biochemical test</Definition> 
          and <Definition word="cn">nuchal translucency ultrasound</Definition> if available in their region. Secondly, <Definition word="tgpni">non-invasive 
          prenatal testing</Definition> is offered to women who have results indicating high <Definition word="probability">probability</Definition> of trisomy 
          following an <Definition word="tbi">integrated biochemical test</Definition>, with or without <Definition word="cn">nuchal translucency ultrasound</Definition>. 
          In special circumstances, <Definition word="tgpni">non-invasive prenatal testing</Definition> may also be offered as a first screening test (see box below).
        </Trans>
      </Typography>
      <AccentFrame>
        <Typography>
          <strong>
            {t("Requirements for non-invasive prenatal testing as a first screening test")}
          </strong>
        </Typography>
        <ol>
          <Li>{t("If, in a previous pregnancy, the baby had trisomy 21, trisomy 18, or trisomy 13.")}</Li>
          <Li>{t("If you are 40 years of age or older at the time of delivery.")}</Li>
          <Li>{t("If you have obtained a prescription following a consultation in genetic medicine.")}</Li>
          <Li>{t("If you are pregnant")}</Li>
        </ol>
      </AccentFrame>
      <InformationTable rows={rows} columns={columns} firstColumnWidth="120px"/>
    </Layout>
  );
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;